import React from "react"
import "./shopping.scss"
import Background from "../../assets/images/subbanner.jpg"
import Product from "../images/product.jpg"
import { Link } from "gatsby"
import ProductCart from "../ProductCart"
import noImage from "../../assets/images/no-img.jpg"
import Loading from "../Loading"
import { useGetAllProducts } from "../../hooks/useGetAllProducts"


const ShoppingComponent = () => {
  const {listProducts,loading} = useGetAllProducts()

  const available_product = listProducts?.edges
    .filter((el) => el.node.regularPrice !== null && el.node.hasOwnProperty("regularPrice"))
  return (
    <div className='shopping-component'>
      <div
        className='shopping-component-banner'
        >
          <img src={Background} alt="banner" />
      </div>
      {!loading ?
        <div className='products'>
          {available_product?.slice(0, 8).map((product) => {
            return (<ProductCart
              key={product.node.id}
              productId={product.node.id}
              productDatabaseId={product.node.databaseId}
              regularPrice={product.node.regularPrice}
              salePrice={product.node.salePrice}
              productName={product.node.name}
              productImage=
                {(product.node.featuredImage) ? product.node.featuredImage.node.sourceUrl : noImage}
              slug={product.node.slug}
              stockStatus={product.node.stockStatus}
              isProductConfig={product.node.__typename === "VariableProduct"}
              isSales={product.node.onSale}
              backInStock={product.node.acfProductData.backInStockNotificationForm}
            >
            </ProductCart>)
          }
          )}
        </div> : <div className="products-loading"><Loading /></div>
      }
      <div className='more-products'>
        <div className='more-products-button'>
          <Link to='/shop/'>more products</Link>
        </div>
      </div>
    </div>
  )
}

export default ShoppingComponent
