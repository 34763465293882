import React from "react"
import "./welcome.scss"
import WelcomeImg from "../images/traditional.jpg"

const Welcome = () => {
  return (
    <div className="welcome-section" id="section1">
      <div className="welcome-row">
        <div className="column-img">
          <span className="image-frame">
            <div className="box-shadow-overlay"></div>
            <img src={WelcomeImg} alt="" />
          </span>
        </div>
        <div className="column-content">
          <h4 className="cairo-font">Welcome to Backa Gourmet Foods</h4>
          <h2 className="vast-shadow-font">Traditionally Crafted Australian Salami</h2>
          <p>
            We are a local producer of fermented dried meats made from local
            produce. We only use selected premium meats which adds to our
            quality and guarantee.
          </p>
          <p>
            Nothing is artificially treated, instead an all-natural process is
            used to achieve a beautiful tasting product.
          </p>
          <p>
            We only salt cure our products and use starter culture to get the
            process started. Glutens and preservatives are not used in any part
            of our process or products.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Welcome
