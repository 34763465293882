import * as React from "react"
import Banner from "../components/Banner"
import Contact from "../components/Contact"
import Heading from "../components/Heading"
import Layout from "../components/layout"
import Letter from "../components/Letter"
import Shopping from "../components/Shopping"
import Welcome from "../components/Welcome"
import { Link } from "gatsby"
import Background from "../components/images/banner.jpg"
import Messenger from "../components/Messenger"
import Seo from "../components/seo"

const letter = [{
  title: "WELL DONE MATE.",
  quote: `“Hi Richard<p></p>
  <p>Usually I don’t do feedbacks about anything, however. 
  I probably come from a similar part of the world like your father. 
  My mother’s uncle was for years a winner of the best 
  Kulen of what was then called former Yugoslavia. 
  So in my time I tried &nbsp;a lot of high quality smoked products.</p>
  <p>After 23 years in Australia I always struggled finding that level 
  of quality that is just another few steps above &nbsp;from what others call quality. 
  And finally here you are.</p>
  <p>Well done mate.”</p>`,
  author:"Alex M"
}]

const bannerImage = [
  {
    image: {
      sourceUrl: Background
    },
    heading: "Australian Made Fermented SmallGoods"
  }
]
const seo = {
  title:"Backa Australia - Australian Made Fermented Smallgoods",
  description:"Australian Salami. Local producer of fermented dried meats made from local produce. We only use selected premium meats Quality is our guarantee."
}
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={seo.title} description={seo.description}/>
      <Banner banner={bannerImage} btnNext={true}>
        <Link to="/shop/">Shop Now! We're Open</Link>
      </Banner>
      <Welcome />
      <Letter testimonial={letter} isHome={true}/>
      <Heading color="#0a7611" supText="100% ARTISAN CRAFTED" title="SELECTION">
        <p>
          Backa Products are all Gluten Free, Keto Friendly and made from top quality meats. All
          orders of $75 or more receive Free Shipping.
        </p>
      </Heading>
      <Shopping />
      <Contact>
        <div className="inner-text">
          <h4>Online</h4>
          <h3>View Our Recipes</h3>
          <p>
            Our products offer a wide variety of uses for people of all tastes. We are always adding
            new recipes to display the versatility of our range. Click the button below to see for
            yourself.
          </p>
          <div className="contact-button">
            <Link to="/recipes/">Our Recipes</Link>
          </div>
        </div>
      </Contact>
    </Layout>
  )
}

export default IndexPage
